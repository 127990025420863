<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md5">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md5">
        <va-select
          v-model="selectedParent"
          :label="$t('trainings.roles.inputs.parentInput')"
          :options="parentsList"
          :loading="isLoading.parents"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md2">
        <va-checkbox
          :label="$t('trainings.roles.inputs.trainInput')"
          :disabled="loading"
          v-model="role.trainer"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { validatorMixin } from '@/services/validator'
import { mapGetters } from 'vuex'

export default {
  name: 'roles-form',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    role: {
      type: Object,
      required: false,
      default: function () { return { trainer: false } },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentLocale']),
    isNew () {
      return this.role.id === 0
    },
  },
  data () {
    return {
      parentsList: [],
      selectedParent: '',
      isLoading: {
        parents: false,
      },
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    role (val) {
      this.setRole(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.getParents()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setRole (val) {
      this.setFormData(val)
      if (val.parent_dcpi_role) {
        this.selectedParent = val.parent_dcpi_role
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
        this.getParents()
      }
    },
    initialData () {
      if (this.role) {
        this.setRole(this.role)
      }
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const role = this.getFormData(this.role)
      if (this.selectedParent && this.selectedParent !== '') {
        role.parent_id = this.selectedParent.id
      }
      delete role.users
      delete role.dcpi_trainings
      delete role.parent_dcpi_role
      delete role.child_dcpi_roles

      this.$emit('submit', role)
    },
    async getParents () {
      let response = false
      let route = 'dcpi-roles'
      if (this.role.id) {
        route += ('?not=' + this.role.id.toString())
      }

      try {
        response = await this.$http.get(route)
      } catch (e) {
        // console.log('Cant fetch roles', e)
        return
      }

      this.parentsList = response.data.data
    },
  },
}
</script>
